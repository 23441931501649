import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import propTypes from "prop-types"

class PageTemplate extends Component {
  render() {
    const id = this.props.pageContext.id
    const parentId = this.props.pageContext.parentId
    const language = this.props.pageContext.language
    const currentPage = this.props.data.wordpressPage
    const subList = this.props.data.allWordpressPage
    var board_members = []
    if (currentPage.wordpress_id === 38 || currentPage.wordpress_id === 112) {
      //board_members = this.props.data.allWordpressWpBoardMembers.edges
      ////(board_members)
    }

    return (
      <Layout language={language}>
        <div className="row about">
          <div className="col-md-3">
            <h2 className="title">{language === "en" ? "Menu" : "Valmynd"}</h2>
            <ul className="sub-menu">
              {subList.edges.map(({ node }) => (
                <li key={node.id} className="menu-item">
                  <Link
                    to={
                      (language === "en" ? "/about/" : "/um-felagid/") +
                      node.slug
                    }
                    activeClassName="current"
                  >
                    <p className="name"> {node.title} </p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-9 main-content">
            <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
            <div className="board-members row">
              {board_members.map(({ node }) => (
                <div className="col-md-4 col-xs-12" key={node.id}>
                  <h3>
                    {language === "en"
                      ? node.acf.title_in_english
                      : node.acf.title}
                  </h3>
                  <p> {node.acf.name} </p>

                  <img src={node.acf.image.source_url} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

PageTemplate.propTypes = {
  data: propTypes.object.isRequired,

  edges: propTypes.array,
}

export default PageTemplate

export const pageQuery = graphql`
  query AboutQuery($id: Int!, $parentId: Int!) {
    allWordpressWpBoardMembers {
      edges {
        node {
          id
          title
          wordpress_id
          acf {
            name
            title_in_english
            title
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      wordpress_id
      title
      content
    }
    allWordpressPage(
      sort: { fields: menu_order, order: ASC }
      filter: { wordpress_parent: { eq: $parentId } }
    ) {
      edges {
        node {
          wordpress_parent
          title
          id
          slug
        }
      }
    }
  }
`
